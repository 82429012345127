import React from 'react';
import {
   makeStyles, Typography, Grid, Box, Button
} from '@material-ui/core';

// Slide
import Slider from "react-slick";
import '../services/slick.css';
import '../services/slick-theme.css';

const useStyles = makeStyles((theme) => ({
   secondary: {
      color: theme.palette.secondary.main,
   },
   dark: {
      color: theme.palette.common.dark,
   },
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   white: {
      color: theme.palette.common.white,
   },
   bold: {
      fontWeight: '600',
   },
   classDescription: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      color: theme.palette.primary.contrastText,
   },
   boxWrapper: {
      // width: '300px',
      // height: '312px',
      [theme.breakpoints.down('sm')]: {
         width: '100vw',
         maxWidth: '340px',
      },
   },
   card: {
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
      color: theme.palette.secondary.main,
      minHeight: '312px',
      boxShadow: 'none',
      padding: '2rem',
      overflow: 'visible',
      margin: '0 16px',
      [theme.breakpoints.down('sm')]: {
         margin: '0 4px',
      },
   },
   dot: {
      fontSize: '1.5rem',
      margin: '0 0.75rem 0 0',
      color: theme.palette.common.light,
   },
   icon: {
      fontSize: '1.5rem',
      margin: '0 0.3rem',
      color: theme.palette.primary.dark,
   },
   iconBg: {
      backgroundColor: theme.palette.primary.light,
      padding: '6px 1px 0px 1px',
      borderRadius: '8px',
      display: 'inline-block',
   },
   listItem: {
      padding: '0',
      marginBottom: '0.75rem',
   },
   boxBg: {
      padding: '2rem',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
   },
   boxBgSecondary: {
      padding: '2rem',
      borderRadius: '8px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
   },
   boxWhite: {
      padding: '2rem',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.white,
   },
   buttonOutlined: {
      width: '100%',
      color: theme.palette.primary.dark,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '600',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: theme.palette.common.white,
      backgroundColor: 'transparent',
      '&:hover': {
         color: theme.palette.common.white,
         backgroundColor: theme.palette.primary.dark,
      },
      [theme.breakpoints.down('sm')]: {
         padding: '0.65rem 0.5rem !important',
      },
   },
   buttonLabel: {
      textTransform: 'none',
      color: theme.palette.common.white,
   },
   divSpace: {
      margin: '0 0.5rem',
   },
   boxCar: {
      borderRadius: '32px',
      border: '1px solid #F1F1F1',
      padding: '1rem',
      boxShadow: ' 0px 0px 10px #F1F1F1',
      minHeight: '280px',
   },
   boxCarShadow: {
      borderRadius: '32px',
      border: '1px solid #F1F1F1',
      padding: '5rem 1rem',
      boxShadow: ' 0px 0px 10px #F1F1F1',
      backgroundColor: theme.palette.primary.dark,
      minHeight: '150px',
   },
   link: {
      color: theme.palette.primary.dark,
      textDecoration: 'none',
   },
   img: {
      margin: '0 auto',
   },
   alert: {
      width: '100%',
      color: theme.palette.common.dark,
      padding: '0.8rem 2.5rem',
      fontSize: '1rem',
      borderRadius: '8px',
      backgroundColor: theme.palette.secondary.light,
      [theme.breakpoints.down('sm')]: {
         padding: '0.65rem 0.5rem !important',
      },
   },
}));

export default function Diferenca() {
   const classes = useStyles();

   // Slide
   var settings = {
      className: 'carrossel',
      infinite: false,
      speed: 1000,
      fade: false,
      autoplay: false,
      autoplaySpeed: 3000,
      slidesToShow: 4,
      swipeToSlide: true,
      arrows: true,
      dots: false,
      responsive: [
         {
            breakpoint: 1441,
            settings: {
               slidesToShow: 4,
            }
         },
         {
            breakpoint: 1200,
            settings: {
               slidesToShow: 2,
            }
         },
         {
            breakpoint: 720,
            settings: {
               slidesToShow: 2,
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
            }
         }
      ],
      afterChange: function (index) {
         console.log(
            `Slider Changed to: ${index + 1}`
         );
      }
   };

   return (

      <Box mt={{ xs: 16, md: 28 }} mb={{ xs: 16, md: 28 }} mx={{ xs: 4, md: 0 }} pb={{ xs: 12, md: 0 }} md={10}>
         {/* <Container> */}

         {/* <Box> */}
         <Grid container justifyContent='space-evenly' alignItems='flex-start'>

            <Grid item md={12}>
               <Box mt={8} mb={14} px={{ xs: 4, md: 16 }}>
                  <Grid container justifyContent='space-between' alignItems='flex-start' spacing={4}>
                     <Grid item>
                        <Typography component='h2' variant='h2' className={classes.dark}>
                           As melhores opções para você.
                        </Typography>
                     </Grid>
                     <Grid item>
                        <Typography component='p' variant='body1' className={classes.dark}>
                           Conheça nossa frota completa e assine agora.
                        </Typography>
                     </Grid>
                  </Grid>
               </Box>
            </Grid>

            {/* <Box mt={{ xs: 24, md: 0 }}> */}
            <Grid container md='12' justifyContent='center' alignContent='center' alignItems='center' spacing={4}>
               <Slider {...settings}>

                  {/* Kicks */}
                  <Box item>
                     <Box textAlign='center' py={4} px={4}>
                        <Box className={classes.boxCar}>
                           <Box mb={8} mt={1}>
                              <img src='https://assinatura.locadoravigorito.com.br/wp-content/uploads/2022/12/74819-Imagem-ve-culo-PNG-_-539x343-1-400x255.png'
                                 className={classes.img} width='auto' height='133px' alt='Vigorito - Novo Kicks Advance CVT' />
                           </Box>
                           <Box mb={4}>
                              <Typography component='h5' variant='h5'>Novo Kicks Advance CVT</Typography>
                              <Typography component='p' variant='body1' className={classes.contrastText}>Modelo SUV</Typography>
                           </Box>
                           <a href='https://assinatura.locadoravigorito.com.br/carro/novo-kicks-advance-cvt/' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>

                  {/* Onix */}
                  <Box item>
                     <Box textAlign='center' py={4} px={4}>
                        <Box className={classes.boxCar}>
                           <Box mb={8} mt={1}>
                              <img src='https://www.vigoritogm.com.br/assets/uploads/nt_veiculos_cores/82308-Cor-Branco-Summit.png'
                                 className={classes.img} width='auto' height='133px' alt='Vigorito - Onix 1.0 LT' />
                           </Box>
                           <Box mb={4}>
                              <Typography component='h5' variant='h5'>Onix Premier Turbo</Typography>
                              <Typography component='p' variant='body1' className={classes.contrastText}>Modelo Hatch</Typography>
                           </Box>
                           <a href='https://assinatura.locadoravigorito.com.br/carro/onix-1-0-lt-mt/' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>
                  
                  {/* Versa */}
                  <Box item>
                     <Box textAlign='center' py={4} px={4}>
                        <Box className={classes.boxCar}>
                           <Box mb={8} mt={1}>
                              <img src='https://assinatura.locadoravigorito.com.br/wp-content/uploads/2023/02/96088-Imagem-ve-culo_-400x255.png'
                                 className={classes.img} width='auto' height='133px' alt='Vigorito - Novo Versa Sense 1.6 CVT' />
                           </Box>
                           <Box mb={4}>
                              <Typography component='h5' variant='h5'>Novo Versa Sense 1.6 CVT</Typography>
                              <Typography component='p' variant='body1' className={classes.contrastText}>Modelo Sedan</Typography>
                           </Box>
                           <a href='https://assinatura.locadoravigorito.com.br/carro/novo-versa-sense-1-6-cvt/' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>
                  
                  {/* Stonic */}
                  <Box item>
                     <Box textAlign='center' py={4} px={4}>
                        <Box className={classes.boxCar}>
                           <Box mb={8} mt={1}>
                              <img src='https://assinatura.locadoravigorito.com.br/wp-content/uploads/2023/04/vw-taos-2023-13-400x213.jpg'
                                 className={classes.img} width='auto' height='133px' alt='Vigorito - TAOS Highline 250 TSI' />
                           </Box>
                           <Box mb={4}>
                              <Typography component='h5' variant='h5'>TAOS Highline 250 TSI</Typography>
                              <Typography component='p' variant='body1' className={classes.contrastText}>Modelo SUV</Typography>
                           </Box>
                           <a href='https://assinatura.locadoravigorito.com.br/carro/taos-highline-250-tsi/' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>
                  
                  {/* Cerato */}
                  {/* <Box item>
                     <Box textAlign='center' py={4} px={4}>
                        <Box className={classes.boxCar}>
                           <Box mb={8} mt={1}>
                              <img src='https://assinatura.locadoravigorito.com.br/wp-content/uploads/2023/04/35698-C-pia-de-Cores_-700x385-4.jpg'
                                 className={classes.img} width='auto' height='133px' alt='Vigorito - KIA Cerato 2.0' />
                           </Box>
                           <Box mb={4}>
                              <Typography component='h5' variant='h5'>KIA Cerato 2.0</Typography>
                              <Typography component='p' variant='body1' className={classes.contrastText}>Modelo Sedan</Typography>
                           </Box>
                           <a href='/' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box> */}
                  
                  {/* Frontier */}
                  <Box item>
                     <Box textAlign='center' py={4} px={4}>
                        <Box className={classes.boxCar}>
                           <Box mb={8} mt={1}>
                              <img src='https://www.vigoritonissan.com.br/assets/uploads/nt_veiculos_cores/16586-Cores_Cinza-Shark_-700x385_.png'
                                 className={classes.img} width='auto' height='133px' alt='Vigorito - Nissan Frontier PRO-4x AT 4x4' />
                           </Box>
                           <Box mb={4}>
                              <Typography component='h5' variant='h5'>Frontier PRO-4x AT 4x4</Typography>
                              <Typography component='p' variant='body1' className={classes.contrastText}>Modelo Picape</Typography>
                           </Box>
                           <a href='https://assinatura.locadoravigorito.com.br/carro/frontier-pro4x-at/' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>

                  {/* LEAF */}
                  <Box item>
                     <Box textAlign='center' py={4} px={4}>
                        <Box className={classes.boxCar}>
                           <Box mb={8} mt={1}>
                              <img src='https://assinatura.locadoravigorito.com.br/wp-content/uploads/2023/04/89148-COR-01-300x145.png'
                                 className={classes.img} width='auto' height='133px' alt='Vigorito - Nissan LEAF' />
                           </Box>
                           <Box mb={4}>
                              <Typography component='h5' variant='h5'>Nissan LEAF</Typography>
                              <Typography component='p' variant='body1' className={classes.contrastText}>Modelo SUV</Typography>
                           </Box>
                           <a href='https://assinatura.locadoravigorito.com.br/carro/leaf/' className={classes.link}>
                              <Typography component='span' variant='body2'>Ver mais detalhes +</Typography>
                           </a>
                        </Box>
                     </Box>
                  </Box>

                  {/* Outros */}
                  <Box item>
                     <Box textAlign='center' px={4} py={4}>
                        <Box className={classes.boxCarShadow}>
                           <Box mb={12}>
                              <Typography component='p' variant='h5' className={classes.white}>
                                 Veja nossa frota completa disponível para você.
                              </Typography>
                           </Box>
                           <Box px={8} mb={{ xs: 20, md: 0 }}>
                              <Button color='secondary' variant='outlined' href='https://assinatura.locadoravigorito.com.br/'
                                 classes={{
                                    root: classes.buttonOutlined,
                                    label: classes.buttonLabel,
                                 }}>Ver carros
                              </Button>
                           </Box>
                        </Box>
                     </Box>
                  </Box>

               </Slider>

            </Grid>
            {/* </Box> */}

         </Grid>
         {/* </Box> */}

         {/* </Container> */}
      </Box>
   );
}