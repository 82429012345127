import React from 'react';
import { makeStyles, Typography, Container, Grid, Box } from '@material-ui/core';

import Bg from '../images/bg.webp';

const useStyles = makeStyles((theme) => ({
   section: {
      width: '100%',
      height: '100vh',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center bottom',
      backgroundImage: `url(${Bg})`,
      [theme.breakpoints.down('sm')]: {
      },
   },
   container: {
      paddingTop: '12rem',
      position: 'relative',
      zIndex: '1',
      [theme.breakpoints.down('sm')]: {
         paddingTop: '96px',
      }
   },
   classTitle: {
      color: theme.palette.common.white,
   },
   classDescription: {
      color: theme.palette.common.white,
   },
}));

function Header(props) {
   const classes = useStyles();
   return (
      <Grid className={classes.section}>
         <Container className={classes.container}>
            <Box py={{ xs: 4, md: 0 }}>
               <Grid container direction='row' justifyContent='center'>
                  <Grid item md='6' className={classes.classBoxText}>
                     <Box mt={{ xs: 6, md: 0 }} px={{ xs: 4, md: 0 }} textAlign='center'>
                        <Typography variant='h1'>
                           <Box mb={8} className={classes.classTitle}>Liberdade e Flexibilidade ao seu dispor.</Box>
                        </Typography>
                        <Typography variant='body1'>
                           <Box mb={{ xs: 12 }} className={classes.classDescription}>
                              Escolha a assinatura perfeita para você e viva o lado bom de um carro zero.
                           </Box>
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>
            </Box>
         </Container>
      </Grid>
   );
}

export default Header;