import React from 'react';
import {
  makeStyles, Container, Typography, Grid, Box, Link
} from '@material-ui/core';
import FloatingWhatsApp from 'react-floating-whatsapp';
import '../services/floating-whatsapp.css';


// Icons
// import { WhatsApp, Phone, Email } from '@material-ui/icons';
import { WhatsApp, Phone } from '@material-ui/icons';
import { Icon } from '@iconify/react';

// Images
import Logo from '../images/logo-footer.webp';
import Avatar from '../images/avatar.webp';

const useStyles = makeStyles((theme) => ({
  bg: {
    background: theme.palette.common.dark,
  },
  white: {
    color: theme.palette.common.white,
  },
  hr: {
    height: '0',
    border: '1px solid rgba(236, 239, 246, 0.1)',
    margin: '2rem 0 1rem 0',
  },
  icon: {
    fontSize: '1.25rem',
    margin: '0 0.75rem 0 0',
    paddingTop: '0.35rem',
    color: theme.palette.primary.main,
  },
  menuItem: {
    color: theme.palette.common.white,
    display: 'block',
    fontSize: '1rem',
    lineHeight: '1rem',
    fontWeight: '400',
    marginBottom: '0.75rem',
    fontFamily: 'Lato, sans-serif',
    textDecoration: 'none',
  },
  buttonOutlined: {
    width: '100%',
    color: theme.palette.primary.main,
    boxShadow: 'none',
    borderRadius: '25px',
    textTransform: 'none',
    padding: '0.5rem 2.5rem',
    fontSize: '1rem',
    fontWeight: '700',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    backgroundColor: 'transparent',
    '&:hover': {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0px 6px 30px rgba(240, 201, 0, 0.6) !important',
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (

    <Box className={classes.bg} pt={20} pb={10} px={6}>
      <Container>

        <Grid container justifyContent='space-between'>

          {/* Logo */}
          <Grid item xs={12} md={3}>
            <Box mb={8}>
              <a href='/'><img src={Logo} alt='Logo' width='200' /></a>
            </Box>
          </Grid>

          {/* Mapa do site */}
          <Grid item md={4}>
            <Box mb={5}>
              <Typography variant='h5' component='h5' className={classes.white}>Mapa do site</Typography>
            </Box>
            <Grid container justifyContent='flex-start' spacing={0}>
              <Grid item xs={12} md={6}>
                <Grid item><a className={classes.menuItem} href='/'>Página Inicial</a></Grid>
                <Grid item><a className={classes.menuItem} href='https://assinatura.locadoravigorito.com.br/'>Nossa Frota</a></Grid>
                <Grid item><a className={classes.menuItem} href='https://assinatura.locadoravigorito.com.br/pedido'>Meus Pedidos</a></Grid>
                <Grid item><a className={classes.menuItem} href='/duvidas'>Tire suas dúvidas</a></Grid>
                <Grid item><a className={classes.menuItem} href='/politica-de-privacidade'>Política de Privacidade</a></Grid>
              </Grid>
              <Grid item xs={12} md={6}>               
                <Grid item><a className={classes.menuItem} href='https://www.vigoritogm.com.br/'>Vigorito GM</a></Grid>
                <Grid item><a className={classes.menuItem} href='https://www.vigoritovw.com.br/'>Vigorito Volks</a></Grid>
                <Grid item><a className={classes.menuItem} href='https://www.vigoritonissan.com.br/'>Vigorito Nissan</a></Grid>
                <Grid item><a className={classes.menuItem} href='https://www.vigoritokia.com.br/'>Vigorito KIA</a></Grid>
                <Grid item><a className={classes.menuItem} href='https://www.vigoritopcd.com.br/'>Vigorito PCD</a></Grid>
                <Grid item><a className={classes.menuItem} href='https://www.vigoritoconsorcio.com.br/'>Vigorito Consórcio</a></Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* Central de atendimento */}
          <Grid item md={2}>
            <Box mt={{ xs: 8, md: 0 }} mb={4}>
              <Typography variant='h5' component='h5' className={classes.white}>Nossos contatos</Typography>
            </Box>
            <Box mb={0}>
              <Grid container justifyContent='flex-start' alignItems='center' alignContent='center'>
                <Grid item>
                  <Phone className={classes.icon} />
                </Grid>
                <Grid item>
                  <Typography variant='body1' component='p' className={classes.white}>
                    (11) 2421-9560
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box mb={0}>
              <Grid container justifyContent='flex-start' alignItems='center' alignContent='center'>
                <Grid item>
                  <WhatsApp className={classes.icon} />
                </Grid>
                <Grid item>
                  <Typography variant='body1' component='p' className={classes.white}>
                    <Link className={classes.white} href='https://api.whatsapp.com/send?phone=551145428050&text=Oi,%20eu%20quero%20falar%20com%20a%20Vigorito!'>(11) 4542-8050</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            {/* <Box mb={8}>
              <Grid container justifyContent='flex-start' alignItems='center' alignContent='center'>
                <Grid item>
                  <Email className={classes.icon} />
                </Grid>
                <Grid item>
                  <Typography variant='body2' component='p' className={classes.white}>
                    <Link className={classes.white} href='mailto:meuclickmobility@grupoleauto.com.br'>meuclickmobility@grupoleauto.com.br</Link>
                  </Typography>
                </Grid>
              </Grid>
            </Box> */}
          </Grid>

          {/* Endereço */}
          <Grid item md={3}>
            <Box mt={{ xs: 8, md: 0 }} mb={4}>
              <Typography variant='h5' component='h5' className={classes.white}>Endereço</Typography>
            </Box>
            <Box className={classes.white} mb={8}>
              <Typography variant='body1' component='p'>
                Av. Guarulhos, 2281<br />
                Vila Augusta - CEP 07025-000 <br/>
                Guarulhos/SP
              </Typography>
            </Box>
            <Box mb={4}>
              <Typography variant='h5' component='h5' className={classes.white}>Redes sociais</Typography>
            </Box>
            <Box mb={8}>
              <Link className={classes.white} href='https://www.instagram.com/vigorito.rentacar/' target="_blank" rel="noreferrer">
                <Icon icon="fa-brands:instagram" className={classes.icon} />
              </Link>
              <Link className={classes.white} href='https://web.facebook.com/vigoritorentacar' target="_blank" rel="noreferrer">
                <Icon icon="fa-brands:facebook" className={classes.icon} />
              </Link> 
            </Box>
          </Grid>

        </Grid>

        <hr className={classes.hr} />

        <Grid container justifyContent='space-between'>
          <Grid item>
            <Typography variant='body2' component='span' className={classes.white}>
              Copyright © Vigorito Rent a Car - Todos os direitos reservados
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='body2' component='span' className={classes.white}>
              <Box mr={1} component='span'>Desenvolvido por</Box>
            </Typography>
            <Link href='https://www.cwa.digital/' target='_blank' className={`${classes.white} ${classes.fontBold}`}>
              <Typography variant='body2' component='span'>
                Cwa Digital
              </Typography>
            </Link>
          </Grid>
        </Grid>

      </Container>

      <FloatingWhatsApp
        className='whats'
        phoneNumber='551145428050'
        accountName='Atendimento'
        avatar={Avatar}
        statusMessage='Responde rápido em horário comercial'
        chatMessage='Olá! 😊 
        Como podemos ajudar?'
        placeholder='Escreva sua mensagem...'
      />

    </Box>
  );
}