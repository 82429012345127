import React from 'react';
import {
   makeStyles, Grid, Box, Typography, Button, Container
} from '@material-ui/core';
import TopBar from '../components/TopBar';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Modelos from '../components/Modelos';
import Beneficios from '../components/Beneficios';
import ConhecaFrota from '../components/ConhecaFrota';

// Images
import Image1 from '../images/img-1.webp';
import Image2 from '../images/img-2.webp';
import BgPlanos from '../images/image-banner.webp';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#FFFFFF',
   },
   primary: {
      color: theme.palette.primary.dark,
   },
   buttonLabel: {
      textTransform: 'none',
   },
   button: {
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 1.65rem',
      fontSize: '1rem',
      fontWeight: '600',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
         boxShadow: 'none',
         color: theme.palette.primary.main,
         backgroundColor: theme.palette.primary.light,
      },
   },
   boxPlanos: {
      backgroundImage: `url(${BgPlanos})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      width: '100%',
      borderRadius: '32px',
      padding: '4rem',
      textAlign: 'center',
      color: theme.palette.common.white,
      [theme.breakpoints.down('sm')]: {
         height: 'auto',
         padding: '2rem',
      }
   }
}));

export default function Home() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>

         <TopBar />

         <Header />
         <Modelos />

         {/* Feito pra você */}
         <Box mt={{ xs: 24, md: 36 }} mb={{ xs: 20, md: 30 }} px={{ xs: 4, md: 0 }}>
            <Container>
               <Box mb={12}>
                  <Grid container justifyContent='space-between' alignItems='center'>
                     {/* Lado direito */}
                     <Grid item md='6'>
                        <img src={Image1} width='100%' alt='Vigorito Rent a Car' />
                     </Grid>
                     {/* Lado direito */}
                     <Grid item md='5'>
                        <Box mt={{ xs: 8, md: 0 }} mb={8}>
                           <Typography component='h2' variant='h2' className={classes.primary}>
                              Feito para você!
                           </Typography>
                        </Box>
                        <Box mb={8} pr={{ xs: 0, md: 12 }}>
                           <Typography component='p' variant='h5' className={classes.classDescription}>
                              Desfrute do melhor de um carro zero sem as preocupações e despesas de compra, manutenção,
                              depreciação e todas as burocracias envolvidas.
                           </Typography>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>

               {/* Versão desktop */}
               <Box display={{ xs: 'none', md: 'block' }}>
                  <Grid container justifyContent='space-between' alignItems='center'>
                     {/* Lado direito */}
                     <Grid item md='5'>
                        <Box pl={{ xs: 0, md: 12 }}>
                           <Box mb={8}>
                              <Typography component='p' variant='h5' className={classes.classDescription}>
                                 Aqui você escolhe o carro perfeito para seu estilo de vida e orçamento,
                                 e nós cuidamos de todo o resto.
                                 Aproveite o máximo sua vida sem perder tempo com manutenções e burocracias.
                              </Typography>
                           </Box>
                           <Button variant='contained' href='https://assinatura.locadoravigorito.com.br/'
                              classes={{
                                 root: classes.button,
                                 label: classes.buttonLabel,
                              }}>Assine agora a Vigorito
                           </Button>
                        </Box>
                     </Grid>
                     {/* Lado direito */}
                     <Grid item md='6'>
                        <img src={Image2} width='100%' alt='Vigorito Rent a Car' />
                     </Grid>
                  </Grid>
               </Box>

               {/* Versão mobile */}
               <Box display={{ xs: 'block', md: 'none' }}>
                  <Grid container justifyContent='space-between' alignItems='center'>
                     <Grid item md='6'>
                        <img src={Image2} width='100%' alt='Vigorito Rent a Car' />
                     </Grid>
                     <Grid item md='5'>
                        <Box pl={{ xs: 0, md: 12 }} mt={8}>
                           <Box mb={8}>
                              <Typography component='p' variant='h5' className={classes.classDescription}>
                                 Aqui você escolhe o carro perfeito para seu estilo de vida e orçamento,
                                 e nós cuidamos de todo o resto.
                                 Aproveite o máximo sua vida sem perder tempo com manutenções e burocracias.
                              </Typography>
                           </Box>
                           <Button variant='contained' href='https://assinatura.locadoravigorito.com.br/'
                              classes={{
                                 root: classes.button,
                                 label: classes.buttonLabel,
                              }}>Assine agora a Vigorito
                           </Button>
                        </Box>
                     </Grid>
                  </Grid>
               </Box>
            </Container>
         </Box>

         <Beneficios />
         <ConhecaFrota />

         {/* Do seu jeito */}
         <Box mt={{ xs: 24, md: 36 }} mb={24} px={{ xs: 4, md: 0 }}>
            <Container className={classes.boxPlanos}>
               <Grid container justifyContent='center'>
                  <Grid item md={5}>
                     <Box mb={8}>
                        <Typography component='h4' variant='h2' className={classes.white}>
                           Do seu jeito, a sua medida.
                        </Typography>
                     </Box>
                     <Box mb={8}>
                        <Typography component='p' variant='body1' className={classes.white}>
                           Os planos da Vigorito por Assinatura são totalmente personalizáveis para você.
                           Aqui você escolhe por quanto tempo e quantos quilometros por mês quer utilizar.
                        </Typography>
                     </Box>
                     <Button variant='contained' href='https://assinatura.locadoravigorito.com.br/'
                        classes={{
                           root: classes.button,
                           label: classes.buttonLabel,
                        }}>Ver planos agora
                     </Button>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         <Footer />

      </Grid>
   );
}