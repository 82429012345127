import React from 'react';
import {
   makeStyles, Typography, Container, Grid, Box, Button
} from '@material-ui/core';

// Icons
import { Icon } from '@iconify/react';


const useStyles = makeStyles((theme) => ({
   bg: {
      backgroundColor: theme.palette.common.light,
   },
   primary: {
      color: theme.palette.primary.main,
   },
   secondary: {
      color: theme.palette.secondary.main,
   },
   dark: {
      color: theme.palette.primary.dark,
   },
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   white: {
      color: theme.palette.common.white,
   },
   bold: {
      fontWeight: '600',
   },
   classDescription: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      color: theme.palette.primary.contrastText,
   },
   boxWrapper: {
      // width: '300px',
      // height: '312px',
      [theme.breakpoints.down('sm')]: {
         width: '100vw',
         maxWidth: '340px',
      },
   },
   card: {
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
      color: theme.palette.secondary.main,
      minHeight: '312px',
      boxShadow: 'none',
      padding: '2rem',
      overflow: 'visible',
      margin: '0 16px',
      [theme.breakpoints.down('sm')]: {
         margin: '0 4px',
      },
   },
   dot: {
      fontSize: '1.5rem',
      margin: '0 0.75rem 0 0',
      color: theme.palette.common.light,
   },
   icon: {
      fontSize: '2.5rem',
      margin: '0.2rem 0.3rem 0rem',
      color: theme.palette.primary.main,
   },
   iconBg: {
      backgroundColor: theme.palette.secondary.light,
      padding: '1rem',
      borderRadius: '32px',
      display: 'inline-block',
   },
   iconBgWhite: {
      backgroundColor: theme.palette.common.white,
      padding: '6px 1px 0px 1px',
      borderRadius: '8px',
      display: 'inline-block',
   },
   listItem: {
      padding: '0',
      marginBottom: '0.75rem',
   },
   boxBg: {
      padding: '2rem',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
   },
   boxBgSecondary: {
      padding: '2rem',
      borderRadius: '8px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
   },
   boxWhite: {
      padding: '3rem 2.5rem',
      borderRadius: '32px',
      backgroundColor: theme.palette.common.white,
   },
   boxPrimary: {
      padding: '3rem 2.5rem',
      borderRadius: '32px',
      backgroundColor: theme.palette.primary.main,
   },
   buttonLabel: {
      textTransform: 'none',
   },
   button: {
      color: theme.palette.primary.dark,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 1.65rem',
      fontSize: '1rem',
      fontWeight: '600',
      backgroundColor: theme.palette.common.white,
      '&:hover': {
         backgroundColor: theme.palette.primary.light,
      },
   },
   divSpace: {
      margin: '0 0.5rem',
   },
   boxCar: {
      width: '100%',
      height: '440px',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
   },
}));

export default function Diferenca() {
   const classes = useStyles();

   return (

      <Box className={classes.bg} mt={{ xs: 0, md: 0 }} mb={{ xs: 4, md: 0 }} px={{ xs: 4, md: 0 }} pt={{ xs: 20, md: 20 }} pb={{ xs: 12, md: 12 }}>
         <Container>
            <Grid container justifyContent='space-between' alignItems='flex-start' spacing={8}>

               {/* Título */}
               <Grid container justifyContent='center'>
                  <Grid item md={5}>
                     <Box mb={8} textAlign='center'>
                        <Typography component='h2' variant='h2'>
                           <span className={classes.dark}>Benefícios de um Plano de Assinatura na </span>
                           <span className={classes.primary}>Vigorito</span>
                        </Typography>
                     </Box>
                  </Grid>
               </Grid>

               <Grid item md={6}>

                  {/* Bloco 1 */}
                  <Box className={classes.boxWhite} mb={8} textAlign={{ xs: 'center', md: 'left' }}>
                     <Grid container justifyContent='space-around' spacing={8} alignItems='flex-start'>
                        <Grid item md={2}>
                           <Box className={classes.iconBg}>
                              <Icon icon="carbon:white-paper" className={classes.icon} />
                           </Box>
                        </Grid>
                        <Grid item md={9}>
                           <Box mb={4}>
                              <Typography component='h5' variant='h5' className={`${classes.dark} ${classes.bold}`}>
                                 Documentação, IPVA, Seguros e demais burocracias aqui não existem
                              </Typography>
                           </Box>
                           <Typography component='p' variant='body1'>
                              Com a Vigorito por Assinatura você vive só o lado bom de ter um carro.
                           </Typography>
                        </Grid>
                     </Grid>
                  </Box>

                  {/* Bloco 2 */}
                  <Box className={classes.boxWhite} mb={8} textAlign={{ xs: 'center', md: 'left' }}>
                     <Grid container justifyContent='space-around' spacing={8} alignItems='flex-start'>
                        <Grid item md={2}>
                           <Box className={classes.iconBg}>
                              <Icon icon="fluent:certificate-24-regular" className={classes.icon} />
                           </Box>
                        </Grid>
                        <Grid item md={9}>
                           <Box mb={4}>
                              <Typography component='h5' variant='h5' className={`${classes.dark} ${classes.bold}`}>
                                 Confiança, Segurança e Garantia da Vigorito
                              </Typography>
                           </Box>
                           <Typography component='p' variant='body1'>
                              O Grupo Vigorito foi fundado em 1924 e caminha junto com o crescimento automotivo no Brasil. São 8 marcas representada, 17 lojas, mais de 430 colaboradores e mais de 100.000 veículos vendidos.
                           </Typography>
                        </Grid>
                     </Grid>
                  </Box>

                  {/* Bloco 3 */}
                  <Box className={classes.boxWhite} mb={8} textAlign={{ xs: 'center', md: 'left' }}>
                     <Grid container justifyContent='space-around' spacing={8} alignItems='flex-start'>
                        <Grid item md={2}>
                           <Box className={classes.iconBg}>
                              <Icon icon="ic:baseline-star-outline" className={classes.icon} />
                           </Box>
                        </Grid>
                        <Grid item md={9}>
                           <Box mb={4}>
                              <Typography component='h5' variant='h5' className={`${classes.dark} ${classes.bold}`}>
                                 Conforto e Praticidade
                              </Typography>
                           </Box>
                           <Typography component='p' variant='body1'>
                              Contratação ágil, rápida aprovação, personalização total do seu carro e ao final do seu contrato você podera renovar por um outro carro totalmente zero.
                           </Typography>
                        </Grid>
                     </Grid>
                  </Box>

               </Grid>

               <Grid item md={6}>

                  {/* Bloco 4 */}
                  <Box className={classes.boxWhite} mb={8} textAlign={{ xs: 'center', md: 'left' }}>
                     <Grid container justifyContent='space-around' spacing={8} alignItems='flex-start'>
                        <Grid item md={2}>
                           <Box className={classes.iconBg}>
                              <Icon icon="mingcute:safety-certificate-line" className={classes.icon} />
                           </Box>
                        </Grid>
                        <Grid item md={9}>
                           <Box mb={4}>
                              <Typography component='h5' variant='h5' className={`${classes.dark} ${classes.bold}`}>
                                 Seguro Total
                              </Typography>
                           </Box>
                           <Typography component='p' variant='body1'>
                              Em nossos planos você pode contar com toda nossa cobertura e assistência 24h, 7 dias por semana. Ou seja, tranquilidade e segurança por onde você for.
                           </Typography>
                        </Grid>
                     </Grid>
                  </Box>

                  {/* Bloco 5 */}
                  <Box className={classes.boxWhite} mb={8} textAlign={{ xs: 'center', md: 'left' }}>
                     <Grid container justifyContent='space-around' spacing={8} alignItems='flex-start'>
                        <Grid item md={2}>
                           <Box className={classes.iconBg}>
                              <Icon icon="ph:currency-circle-dollar" className={classes.icon} />
                           </Box>
                        </Grid>
                        <Grid item md={9}>
                           <Box mb={4}>
                              <Typography component='h5' variant='h5' className={`${classes.dark} ${classes.bold}`}>
                                 O melhor Custo Benefício
                              </Typography>
                           </Box>
                           <Typography component='p' variant='body1'>
                              Com nossos planos você tem o melhor de um carro zero sem se descaptalizar, com zero prejuizo com depreciação, custo de manutenção super reduzidos e a possibilidade de troca ao final do seu contrato.
                           </Typography>
                        </Grid>
                     </Grid>
                  </Box>

                  {/* Bloco 6 */}
                  <Box className={classes.boxPrimary} mb={8} textAlign='center'>
                     <Grid container justifyContent='center' spacing={4} alignItems='flex-start'>
                        <Grid item md={8}>
                           <Box mb={9}>
                              <Typography component='h4' variant='h4' className={classes.white}>
                                 Assine agora a Vigorito e viva o lado bom de um carro zero.
                              </Typography>
                           </Box>
                           <Button variant='contained' href='https://assinatura.locadoravigorito.com.br/'
                              classes={{
                                 root: classes.button,
                                 label: classes.buttonLabel,
                              }}>Assine agora a Vigorito
                           </Button>
                        </Grid>
                     </Grid>
                  </Box>

               </Grid>

            </Grid>
         </Container>
      </Box>
   );
}