import React from 'react';
import {
   makeStyles, Grid, Container, Box, Typography
} from '@material-ui/core';
import TopBarSecondary from '../components/TopBarSecondary';
import Footer from '../components/Footer';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#FFFFFF',
   },
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   section: {
      width: '100%',
      height: '760px',
      position: 'relative',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left top',
      [theme.breakpoints.down('sm')]: {

      },
   },
   container: {
      paddingTop: '150px',
      position: 'relative',
      zIndex: '1',
   },
   classSubTitle: {
      color: theme.palette.primary.dark,
      fontFamily: 'Lato, sans-serif',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.25rem',
   },
   classDescription: {
      color: theme.palette.common.main,
   },
   classImage: {
      marginLeft: '7rem',
      width: '554px',
      [theme.breakpoints.down('sm')]: {
         marginLeft: '0',
      }
   },
   buttonLabel: {
      textTransform: 'none',
   },
   button: {
      color: theme.palette.common.white,
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 2.5rem',
      fontSize: '1rem',
      fontWeight: '800',
      backgroundColor: theme.palette.primary.dark,
      '&:hover': {
         backgroundColor: theme.palette.secondary.main,
      },
   },
   iconHeader: {
      float: 'left',
      fontSize: '1.5rem',
      margin: '-0.25rem 0.5rem 0 0',
      color: theme.palette.primary.dark,
   },
   icon: {
      float: 'left',
      fontSize: '1rem',
      margin: '0.25rem 1rem 0 0',
      color: theme.palette.primary.dark,
   },
   iconBg: {
      backgroundColor: theme.palette.primary.light,
      padding: '6px 1px 0px 1px',
      marginTop: '3px',
      borderRadius: '8px',
      display: 'inline-block',
   },
   dark: {
      color: theme.palette.common.dark,
   },
   listItem: {
      padding: '0',
      marginBottom: '0.4rem',
   },
   box: {
      borderRadius: '8px',
      border: '1px solid rgba(111, 111, 120, 0.1)',
      marginBottom: '2rem',
   },
   boxText: {
      padding: '1rem 2rem',
   },
   accordion: {
      backgroundColor: `${theme.palette.common.light} !important`,
      borderRadius: '8px !important',
      marginBottom: '1.5rem',
      border: 'none !important',
      boxShadow: 'none !important',
      '&::before': {
         backgroundColor: 'transparent !important',
      }
   },
   accordionGrid: {
      paddingBottom: '0 !important',
   },
}));

export default function Home() {
   const classes = useStyles();

   return (
      <Grid className={classes.root}>

         <TopBarSecondary />

         {/* Como podemos te ajudar? */}
         <Box px={{ xs: 4, md: 0 }} mb={{ xs: 12, md: 25 }}>
            <Container className={classes.container}>
               <Grid container justifyContent='space-between' alignItems='center'>
                  <Grid item md={5}>
                     <Typography variant='h1' component='h2'>
                        <Box mb={2} className={classes.classSubTitle}>
                           Tire suas dúvidas
                        </Box>
                     </Typography>
                     <Typography variant='h1'>
                        <Box mb={{ xs: 6, md: 0 }} className={classes.classTitle}>
                           Como podemos te ajudar?
                        </Box>
                     </Typography>
                  </Grid>
                  <Grid item md={4}>
                     <Typography variant='h5'>
                        <Box mb={{ xs: 12, md: 0 }} pt={{ xs: 0, md: 8 }} className={classes.classDescription}>
                           Se você tem alguma dúvida que não está listada aqui no site, por favor,
                           entre em contato ou nos mande sua pergunta.
                        </Box>
                     </Typography>
                  </Grid>
               </Grid>
            </Container>
         </Box>

         <Box px={{ xs: 4, md: 0 }} mb={25}>
            <Container>

               <Grid container justifyContent='space-between' spacing={8}>
                  <Grid item md={4} className={classes.accordionGrid}>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                           <Typography className={classes.contrastText}>Existe um limite de Quilometragem?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              Sim, você escolhe sua franquia entre as seguintes opções: 1.000, 1.500 e 2.000km.
                              As apurações de quilometragem são feitas via telemetria do veículo.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                           <Typography className={classes.contrastText}>Os carros são zero km ou usados?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              Todos os carros são 0km. Você escolhe a marca e o modelo, nós o adquirimos e
                              entregamos pronto para você usar com todas as documentações e taxas pagas.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                           <Typography className={classes.contrastText}>O preço é fixo ou muda a cada mês?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              O valor mensal da assinatura do seu carro é fixo. Apenas se houver
                              manutenções não previstas no veículo, as possíveis multas e/ou
                              quilômetros excedentes da franquia, haverá variação no valor.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
                           <Typography className={classes.contrastText}>Qual é a forma de pagamento?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              Cartão de crédito, com cobrança recorrente. Ou seja, o limite do seu cartão
                              não é comprometido com o valor total da assinatura.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel5a-content" id="panel5a-header">
                           <Typography className={classes.contrastText}>Preciso pagar IPVA, licenciamento?</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              Não, você fica sem se preocupar com
                              documentações e impostos (IPVA,
                              licenciamento e seguro obrigatório)
                              relacionados ao veículo por assinatura.
                              Todos eles são pagos pela Vigorito.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                  </Grid>
                  <Grid item md={4} className={classes.accordionGrid}>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel6a-content" id="panel6a-header">
                           <Typography className={classes.contrastText}>
                              O que é preciso para contratar o serviço?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              Para alugar um veículo, você precisa ter acima
                              de 18 anos, CPF válido, carteira de motorista
                              definitiva e enviar comprovantes de renda e
                              residência. Atenção: a mensalidade do aluguel
                              não pode comprometer mais de 35%
                              da sua renda.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel7a-content" id="panel7a-header">
                           <Typography className={classes.contrastText}>
                              Onde posso receber o carro?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              Você pode receber o veículo nas principais
                              cidades/concessionárias da Vigorito em
                              MG/RJ/ES e conforme data e horário marcado.
                              Se preferir, agendamos a entrega no local
                              escolhido por você (sob uma consulta prévia). Para as demais cidades da região Sudeste e afins, é preciso consultar a disponibilidade.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel8a-content" id="panel8a-header">
                           <Typography className={classes.contrastText}>
                              Posso fazer contrato como PJ?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              Sim. Neste caso são
                              veículos destinados às diretorias e altas/médias gerências das companhias e clientes
                              corporativos.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel9a-content" id="panel9a-header">
                           <Typography className={classes.contrastText}>
                              Como funciona a proteção do veículo?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              A Vigorito recomenda revisões preventivas
                              de custo zero. Caso você precise de reboque,
                              chaveiro ou carga de bateria/afins, você
                              não paga nada por isso e a cobertura é 24h. A Vigorito auxilia e define
                              o fornecedor ideal em um curto período de
                              tempo para o reparo.
                              A cobertura é integral (condicionada ao
                              pagamento de proteção/coparticipação
                              obrigatória) caso ocorra sinistros, roubo, furto,
                              perdas e danos causados ao veículo de acordo
                              com contrato.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel10a-content" id="panel10a-header">
                           <Typography className={classes.contrastText}>
                              Tenho direito a carro reserva?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              Sim. Há a opção de contratar/incluir carro reserva por tempo limitado
                              para as manutenções.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                  </Grid>
                  <Grid item md={4} className={classes.accordionGrid}>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel11a-content" id="panel11a-header">
                           <Typography className={classes.contrastText}>
                              O que acontece em caso de multa?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              A Vigorito paga a multa, repassa o valor
                              para o cliente e indica a pontuação ao
                              responsável conforme previsto em contrato.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel12a-content" id="panel12a-header">
                           <Typography className={classes.contrastText}>
                              O carro pode ter mais de um motorista?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              Pode sim! Basta cadastrá-lo nos formulários e
                              enviar os documentos necessários. A proteção
                              cobrirá somente os motoristas cadastrados por
                              veículo conforme contrato de assinatura.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel13a-content" id="panel13a-header">
                           <Typography className={classes.contrastText}>
                              Posso ter mais de um carro no meu nome?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              Sim, desde que você apresente uma renda
                              compatível com a solicitação e consiga custear
                              as parcelas de mais de um veículo.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel14a-content" id="panel14a-header">
                           <Typography className={classes.contrastText}>
                              O documento do carro fica no nome de quem?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              O documento do carro contratado fica em
                              nome da locadora Vigorito, mas o uso é
                              exclusivo do cliente.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                     <Accordion className={classes.accordion}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel15a-content" id="panel15a-header">
                           <Typography className={classes.contrastText}>
                              Posso incluir acessórios?
                           </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           <Typography className={classes.contrastText}>
                              Não. Os acessórios e os equipamentos
                              disponíveis só podem ser definidos em pacotes
                              junto à Vigorito no momento da contratação e por um valor à parte ao
                              valor da parcela mensal da sua assinatura.
                              O nosso objetivo é não modificar, não danificar
                              o veículo e não causar possíveis problemas
                              mecânicos ou elétricos com instalações
                              realizadas fora dos padrões das marcas.
                           </Typography>
                        </AccordionDetails>
                     </Accordion>
                  </Grid>
               </Grid>

            </Container>
         </Box>

         <Footer />

      </Grid>
   );
}