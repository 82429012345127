import React from 'react';
import {
   makeStyles, Typography, Container, Grid, Box,
   List, ListItem, ListItemIcon, Button
} from '@material-ui/core';

// Slide
import Slider from "react-slick";
import '../services/slick.css';
import '../services/slick-theme.css';

// Icons
import { Icon } from '@iconify/react';

// Images
import Onix from '../images/onix.webp';
import OnixPlus from '../images/onix-plus.webp';
import Kicks from '../images/kicks.webp';

const useStyles = makeStyles((theme) => ({
   secondary: {
      color: theme.palette.secondary.main,
   },
   dark: {
      color: theme.palette.primary.dark,
   },
   contrastText: {
      color: theme.palette.primary.contrastText,
   },
   bold: {
      fontWeight: '600',
   },
   classDescription: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
      color: theme.palette.primary.contrastText,
   },
   boxWrapper: {
      // width: '300px',
      // height: '312px',
      [theme.breakpoints.down('sm')]: {
         width: '100vw',
         maxWidth: '340px',
      },
   },
   card: {
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
      color: theme.palette.secondary.main,
      minHeight: '312px',
      boxShadow: 'none',
      padding: '2rem',
      overflow: 'visible',
      margin: '0 16px',
      [theme.breakpoints.down('sm')]: {
         margin: '0 4px',
      },
   },
   icon: {
      fontSize: '1.5rem',
      margin: '0 0.3rem',
      color: theme.palette.primary.main,
   },
   iconBg: {
      backgroundColor: theme.palette.primary.light,
      padding: '6px 1px 0px 1px',
      borderRadius: '8px',
      display: 'inline-block',
   },
   listItem: {
      padding: '0',
      marginBottom: '0.5rem',
   },
   boxBg: {
      padding: '1.5rem',
      borderRadius: '8px',
      backgroundColor: theme.palette.common.light,
   },
   boxBgSecondary: {
      padding: '1.5rem',
      borderRadius: '8px',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
   },
   buttonLabel: {
      textTransform: 'none',
   },
   button: {
      boxShadow: 'none',
      borderRadius: '8px',
      textTransform: 'none',
      padding: '0.65rem 1.65rem',
      textAlign: 'center',
      fontSize: '1rem',
      fontWeight: '600',
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
         boxShadow: 'none',
         color: theme.palette.primary.main,
         backgroundColor: theme.palette.primary.light,
      },
      [theme.breakpoints.down('sm')]: {
         fontSize: '0.9rem',
      },
   },
   divSpace: {
      margin: '0 0.5rem',
   },
   boxCar: {
      width: '100%',
      height: 'auto',
      // borderRadius: '8px',
      // backgroundColor: theme.palette.common.light,
      [theme.breakpoints.down('sm')]: {
         height: '100%',
      },
   },
   image: {
      width: '488px',
      [theme.breakpoints.down('sm')]: {
         width: '100%',
      },
   },
}));

export default function Diferenca() {
   const classes = useStyles();

   // Slide
   var settings = {
      infinite: true,
      speed: 1000,
      fade: true,
      autoplay: false,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
      dots: true,
      responsive: [
         {
            breakpoint: 720,
            settings: {

            }
         },
         {
            breakpoint: 480,
            settings: {

            }
         }
      ]
   };

   return (

      <Box id='modelos' mt={{ xs: 12, md: 24 }} mb={{ xs: 4, md: 24 }} mx={{ xs: 4, md: 0 }}>
         <Container>

            {/* Título      */}
            <Grid item md={12}>
               <Box mt={8} textAlign='center'>
                  <Box mb={4}>
                     <Typography component='h2' variant='h2' className={classes.dark}>
                        Aqui na Vigorito você economiza
                     </Typography>
                  </Box>
                  <Typography component='p' variant='body1' className={classes.classDescription}>
                     Navegue pelas abas e confira os custos de um carro próprio em 12 meses.
                  </Typography>
               </Box>
            </Grid>

            {/* Slide Desktop... */}
            <Box display={{ xs: 'none', md: 'block' }} mt={32}>
               <Slider {...settings}>

                  {/* Modelo Hatch */}
                  <Box>
                     <Grid container justifyContent='space-between' alignItems='center'>

                        {/* Lista */}
                        <Grid item md='5'>
                           <Grid item md={12}>
                              <List>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>Modelo Hatch Preço Público Oficial</Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 78.590,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>Custo do capital investido Rentabilidade de 14% a.a.</Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 11.002,60</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             Depreciação 20% pela tabela FIPE
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 15.718,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             Seguro 6% do valor do carro
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 4.715,40</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             IPVA 4% do valor do carro
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 3.143,60</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             Documentação Emplacamento<br />Licenciamento / DPVAT
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 1.600,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             Manutenção preventiva
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 800,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                              </List>
                           </Grid>
                        </Grid>

                        {/* Modelos de carros */}
                        <Grid item md={7}>
                           <Grid container justifyContent='flex-end' alignItems='center'>
                              <Grid item md={11}>
                                 <Box position='relative'>
                                    <Grid container className={classes.boxCar} justifyContent='center' alignItems='center'>
                                       <img src={Onix} width='488px' alt='Vigorito Rent a Car - Modelo Hatch' />
                                    </Grid>
                                 </Box>
                              </Grid>
                           </Grid>
                        </Grid>

                        {/* Quadros */}
                        <Grid item md={12}>
                           <Box mt={6} textAlign='center'>
                              <Grid container justifyContent='space-between' alignItems='center'>
                                 <Grid item md={6}>
                                    <Grid container>
                                       <Grid item md={12} className={classes.boxBg}>
                                          <Grid container justifyContent='' spacing={4}>
                                             <Grid item md={6}>
                                                <Box textAlign='center'>
                                                   <Box mb={3}>
                                                      <Typography component='div' variant='body1' className={classes.contrastText}>
                                                         Peso anual no seu bolso
                                                      </Typography>
                                                   </Box>
                                                   <Typography component='div' variant='h3' className={classes.contrastText}>
                                                      R$ 36.979,60
                                                   </Typography>
                                                </Box>
                                             </Grid>
                                             <Grid item md={6}>
                                                <Box textAlign='center'>
                                                   <Box mb={3}>
                                                      <Typography component='div' variant='body1' className={classes.contrastText}>
                                                         Peso mensal no seu bolso
                                                      </Typography>
                                                   </Box>
                                                   <Typography component='div' variant='h3' className={classes.contrastText}>
                                                      R$ 3.081,63
                                                   </Typography>
                                                </Box>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                                 <Grid container md={6} justifyContent='space-between' alignItems='center'>
                                    <Grid item md={2}>
                                       <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                    </Grid>
                                    <Grid item md={10}>
                                       <Box className={classes.boxBgSecondary}>
                                          <Box mb={3}>
                                             <Typography component='div' variant='body1'>
                                                Com a assinatura Vigorito com tudo incluso por mês
                                             </Typography>
                                          </Box>
                                          <Typography component='div' variant='h3'>
                                             R$ 1.999,00
                                          </Typography>
                                       </Box>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>

                     </Grid>
                  </Box>

                  {/* Modelo Sedan */}
                  <Box>
                     <Grid container justifyContent='space-between' alignItems='center'>

                        {/* Lista */}
                        <Grid item md='5'>
                           <Grid item md={12}>
                              <List>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>Modelo Sedan Preço Público Oficial</Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 90.390,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>Custo do capital investido Rentabilidade de 14% a.a.</Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 12.654,60</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             Depreciação 20% pela tabela FIPE
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 18.078,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             Seguro 6% do valor do carro
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 5.423,40</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             IPVA 4% do valor do carro
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 3.615,60</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             Documentação Emplacamento<br />Licenciamento / DPVAT
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 1.600,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             Manutenção preventiva
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 800,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                              </List>
                           </Grid>
                        </Grid>

                        {/* Modelos de carros */}
                        <Grid item md={7}>
                           <Grid container justifyContent='flex-end' alignItems='center'>
                              <Grid item md={11}>
                                 <Grid container className={classes.boxCar} justifyContent='center' alignItems='center'>
                                    <img src={OnixPlus} width='488px' alt='Vigorito Rent a Car - Modelo Sedan' />
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>

                        {/* Quadros */}
                        <Grid item md={12}>
                           <Box mt={6} textAlign='center'>
                              <Grid container justifyContent='space-between' alignItems='center'>
                                 <Grid item md={6}>
                                    <Grid container>
                                       <Grid item md={12} className={classes.boxBg}>
                                          <Grid container justifyContent='' spacing={4}>
                                             <Grid item md={6}>
                                                <Box textAlign='center'>
                                                   <Box mb={3}>
                                                      <Typography component='div' variant='body1' className={classes.contrastText}>
                                                         Peso anual no seu bolso
                                                      </Typography>
                                                   </Box>
                                                   <Typography component='div' variant='h3' className={classes.contrastText}>
                                                      R$ 42.171,60
                                                   </Typography>
                                                </Box>
                                             </Grid>
                                             <Grid item md={6}>
                                                <Box textAlign='center'>
                                                   <Box mb={3}>
                                                      <Typography component='div' variant='body1' className={classes.contrastText}>
                                                         Peso mensal no seu bolso
                                                      </Typography>
                                                   </Box>
                                                   <Typography component='div' variant='h3' className={classes.contrastText}>
                                                      R$ 3.514,30
                                                   </Typography>
                                                </Box>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                                 <Grid container md={6} justifyContent='space-between' alignItems='center'>
                                    <Grid item md={2}>
                                       <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                    </Grid>
                                    <Grid item md={10}>
                                       <Box className={classes.boxBgSecondary}>
                                          <Box mb={3}>
                                             <Typography component='div' variant='body1'>
                                                Com a assinatura Vigorito com tudo incluso por mês
                                             </Typography>
                                          </Box>
                                          <Typography component='div' variant='h3'>
                                             R$ 2.399,00
                                          </Typography>
                                       </Box>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>

                     </Grid>
                  </Box>

                  {/* Modelo SUV */}
                  <Box>
                     <Grid container justifyContent='space-between' alignItems='center'>

                        {/* Lista */}
                        <Grid item md='5'>
                           <Grid item md={12}>
                              <List>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>Modelo SUV Preço Público Oficial</Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 127.590,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>Custo do capital investido Rentabilidade de 14% a.a.</Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 17.862,60</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             Depreciação 20% pela tabela FIPE
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 25.518,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             Seguro 6% do valor do carro
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 7.655,40</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             IPVA 4% do valor do carro
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 5.103,60</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             Documentação Emplacamento<br />Licenciamento / DPVAT
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 1.600,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item md={1}>
                                          <ListItemIcon>
                                             <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                          </ListItemIcon>
                                       </Grid>
                                       <Grid item md={7}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>
                                             Manutenção preventiva
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>R$ 800,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                              </List>
                           </Grid>
                        </Grid>

                        {/* Modelos de carros */}
                        <Grid item md={7}>
                           <Grid container justifyContent='flex-end' alignItems='center'>
                              <Grid item md={11}>
                                 <Grid container className={classes.boxCar} justifyContent='center' alignItems='center'>
                                    <img src={Kicks} width='488px' alt='Vigorito Rent a Car - Modelo SUV' />
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>

                        {/* Quadros */}
                        <Grid item md={12}>
                           <Box mt={6} textAlign='center'>
                              <Grid container justifyContent='space-between' alignItems='center'>
                                 <Grid item md={6}>
                                    <Grid container>
                                       <Grid item md={12} className={classes.boxBg}>
                                          <Grid container justifyContent='' spacing={4}>
                                             <Grid item md={6}>
                                                <Box textAlign='center'>
                                                   <Box mb={3}>
                                                      <Typography component='div' variant='body1' className={classes.contrastText}>
                                                         Peso mensal no seu bolso
                                                      </Typography>
                                                   </Box>
                                                   <Typography component='div' variant='h3' className={classes.contrastText}>
                                                      R$ 4.894,97
                                                   </Typography>
                                                </Box>
                                             </Grid>
                                             <Grid item md={6}>
                                                <Box textAlign='center'>
                                                   <Box mb={3}>
                                                      <Typography component='div' variant='body1' className={classes.contrastText}>
                                                         Peso anual no seu bolso
                                                      </Typography>
                                                   </Box>
                                                   <Typography component='div' variant='h3' className={classes.contrastText}>
                                                      R$ 58.739,60
                                                   </Typography>
                                                </Box>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                                 <Grid container md={6} justifyContent='space-between' alignItems='center'>
                                    <Grid item md={2}>
                                       <Icon icon="bi:arrow-right-short" className={classes.icon} />
                                    </Grid>
                                    <Grid item md={10}>
                                       <Box className={classes.boxBgSecondary}>
                                          <Box mb={3}>
                                             <Typography component='div' variant='body1'>
                                                Com a assinatura Vigorito com tudo incluso por mês
                                             </Typography>
                                          </Box>
                                          <Typography component='div' variant='h3'>
                                             R$ 3.499,00
                                          </Typography>
                                       </Box>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>

                     </Grid>
                  </Box>

               </Slider>
            </Box>

            {/* Slide Mobile... */}
            <Box display={{ xs: 'block', md: 'none' }} mt={12}>
               <Slider {...settings}>

                  {/* Modelo Hatch */}
                  <Box>
                     <Grid container justifyContent='space-between' alignItems='center'>

                        {/* Modelos de carros */}
                        <Grid item md={7}>
                           <Grid container justifyContent='flex-end' alignItems='center'>
                              <Grid item md={11}>
                                 <Box position='relative'>
                                    <Grid container className={classes.boxCar} justifyContent='center' alignItems='center'>
                                       <img src={Onix} className={classes.image} alt='Vigorito Rent a Car - Modelo Hatch' />
                                    </Grid>
                                 </Box>
                              </Grid>
                           </Grid>
                        </Grid>

                        {/* Lista */}
                        <Grid item md={12}>
                           <Box mt={6}>
                              <List>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignContent='center' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>Modelo Hatch Preço Público Oficial</Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 78.590,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>Custo do capital investido Rentabilidade de 10% a.a.</Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 7.859,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             Depreciação 20% pela tabela FIPE
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 15.718,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             Seguro 6% do valor do carro
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 4.715,40</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             IPVA 4% do valor do carro
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 3.143,60</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             Documentação Emplacamento<br />Licenciamento / DPVAT
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 1.600,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             Manutenção preventiva
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 800,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                              </List>
                           </Box>
                        </Grid>

                        {/* Quadros */}
                        <Grid item md={12}>
                           <Box mt={6} textAlign='center'>
                              <Grid container justifyContent='space-between' alignItems='center'>
                                 <Grid item xs={12} md={5}>
                                    <Box mb={6}>
                                       <Grid container>
                                          <Grid item md={10} className={classes.boxBg}>
                                             <Grid container justifyContent='center' spacing={4}>
                                                <Grid item md={6}>
                                                   <Box textAlign='center'>
                                                      <Box mb={3}>
                                                         <Typography component='div' variant='body1' className={classes.contrastText}>
                                                            Peso mensal no seu bolso
                                                         </Typography>
                                                      </Box>
                                                      <Typography component='div' variant='h3' className={classes.secondary}>
                                                         R$ 2.819,67
                                                      </Typography>
                                                   </Box>
                                                </Grid>
                                                <Grid item md={6}>
                                                   <Box textAlign='center'>
                                                      <Box mb={3}>
                                                         <Typography component='div' variant='body1' className={classes.contrastText}>
                                                            Peso anual no seu bolso
                                                         </Typography>
                                                      </Box>
                                                      <Typography component='div' variant='h3' className={classes.secondary}>
                                                         R$ 33.836,00
                                                      </Typography>
                                                   </Box>
                                                </Grid>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                    </Box>
                                 </Grid>
                                 <Grid container xs={12} md={7} justifyContent='space-between' alignItems='center'>
                                    <Grid item md={5}>
                                       <Box className={classes.boxBgSecondary}>
                                          <Box mb={3}>
                                             <Typography component='div' variant='body1'>
                                                Com a assinatura Vigorito com tudo incluso por mês
                                             </Typography>
                                          </Box>
                                          <Typography component='div' variant='h3'>
                                             R$ 1.999,00
                                          </Typography>
                                       </Box>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>

                     </Grid>
                  </Box>

                  {/* Modelo Sedan */}
                  <Box>
                     <Grid container justifyContent='space-between' alignItems='center'>

                        {/* Modelos de carros */}
                        <Grid item md={7}>
                           <Grid container justifyContent='flex-end' alignItems='center'>
                              <Grid item md={11}>
                                 <Grid container className={classes.boxCar} justifyContent='center' alignItems='center'>
                                    <img src={OnixPlus} className={classes.image} alt='Vigorito Rent a Car - Modelo Sedan' />
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>

                        {/* Lista */}
                        <Grid item md={12}>
                           <Box mt={6}>
                              <List>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>Modelo Sedan Preço Público Oficial</Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 90.390,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>Custo do capital investido Rentabilidade de 10% a.a.</Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 9.039,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             Depreciação 20% pela tabela FIPE
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 18.078,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             Seguro 6% do valor do carro
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 5.423,40</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             IPVA 4% do valor do carro
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 3.615,60</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             Documentação Emplacamento<br />Licenciamento / DPVAT
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 1.600,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             Manutenção preventiva
                                          </Typography>
                                       </Grid>
                                       <Grid item md={3}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 800,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                              </List>
                           </Box>
                        </Grid>

                        {/* Quadros */}
                        <Grid item md={12}>
                           <Box mt={6} textAlign='center'>
                              <Grid container justifyContent='space-between' alignItems='center'>
                                 <Grid item xs={12} md={5}>
                                    <Box mb={6}>
                                       <Grid container>
                                          <Grid item md={10} className={classes.boxBg}>
                                             <Grid container justifyContent='center' spacing={4}>
                                                <Grid item md={6}>
                                                   <Box textAlign='center'>
                                                      <Box mb={3}>
                                                         <Typography component='div' variant='body1' className={classes.contrastText}>
                                                            Peso mensal no seu bolso
                                                         </Typography>
                                                      </Box>
                                                      <Typography component='div' variant='h3' className={classes.secondary}>
                                                         R$ 3.213,00
                                                      </Typography>
                                                   </Box>
                                                </Grid>
                                                <Grid item md={6}>
                                                   <Box textAlign='center'>
                                                      <Box mb={3}>
                                                         <Typography component='div' variant='body1' className={classes.contrastText}>
                                                            Peso anual no seu bolso
                                                         </Typography>
                                                      </Box>
                                                      <Typography component='div' variant='h3' className={classes.secondary}>
                                                         R$ 38.556,00
                                                      </Typography>
                                                   </Box>
                                                </Grid>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                    </Box>
                                 </Grid>
                              </Grid>
                              <Grid container md={7} justifyContent='space-between' alignItems='center'>
                                 <Grid item md={5}>
                                    <Box className={classes.boxBgSecondary}>
                                       <Box mb={3}>
                                          <Typography component='div' variant='body1'>
                                             Com a assinatura Vigorito com tudo incluso por mês
                                          </Typography>
                                       </Box>
                                       <Typography component='div' variant='h3'>
                                          R$ 2.399,00
                                       </Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>

                  {/* Modelo SUV */}
                  <Box>
                     <Grid container justifyContent='space-between' alignItems='center'>

                        {/* Modelos de carros */}
                        <Grid item md={7}>
                           <Grid container justifyContent='flex-end' alignItems='center'>
                              <Grid item md={11}>
                                 <Grid container className={classes.boxCar} justifyContent='center' alignItems='center'>
                                    <img src={Kicks} className={classes.image} alt='Vigorito Rent a Car - Modelo SUV' />
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>

                        {/* Lista */}
                        <Grid item md={12}>
                           <Box mt={6}>
                              <List>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>Modelo SUV Preço Público Oficial</Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 127.590,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>Custo do capital investido Rentabilidade de 10% a.a.</Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 12.759,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             Depreciação 20% pela tabela FIPE
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 25.518,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             Seguro 6% do valor do carro
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 7.655,40</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             IPVA 4% do valor do carro
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 5.103,60</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             Documentação Emplacamento<br />Licenciamento / DPVAT
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 1.600,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                                 <ListItem className={classes.listItem}>
                                    <Grid container justifyContent='space-between' alignItems='flex-start' spacing={2}>
                                       <Grid item xs={7}>
                                          <Typography component='p' variant='body1' className={classes.contrastText}>
                                             Manutenção preventiva
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography component='p' variant='body1' className={classes.secondary}>R$ 800,00</Typography>
                                       </Grid>
                                    </Grid>
                                 </ListItem>
                              </List>
                           </Box>
                        </Grid>

                        {/* Quadros */}
                        <Grid item md={12}>
                           <Box mt={6} textAlign='center'>
                              <Grid container justifyContent='space-between' alignItems='center'>
                                 <Grid item xs={12} md={5}>
                                    <Box mb={6}>
                                       <Grid container>
                                          <Grid item md={10} className={classes.boxBg}>
                                             <Grid container justifyContent='center' spacing={4}>
                                                <Grid item md={6}>
                                                   <Box textAlign='center'>
                                                      <Box mb={3}>
                                                         <Typography component='div' variant='body1' className={classes.contrastText}>
                                                            Peso mensal no seu bolso
                                                         </Typography>
                                                      </Box>
                                                      <Typography component='div' variant='h3' className={classes.secondary}>
                                                         R$ 4.469,67
                                                      </Typography>
                                                   </Box>
                                                </Grid>
                                                <Grid item md={6}>
                                                   <Box textAlign='center'>
                                                      <Box mb={3}>
                                                         <Typography component='div' variant='body1' className={classes.contrastText}>
                                                            Peso anual no seu bolso
                                                         </Typography>
                                                      </Box>
                                                      <Typography component='div' variant='h3' className={classes.secondary}>
                                                         R$ 53.636,00
                                                      </Typography>
                                                   </Box>
                                                </Grid>
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                    </Box>
                                 </Grid>
                                 <Grid container md={7} justifyContent='space-between' alignItems='center'>
                                    <Grid item md={5}>
                                       <Box className={classes.boxBgSecondary}>
                                          <Box mb={3}>
                                             <Typography component='div' variant='body1'>
                                                Com a assinatura Vigorito com tudo incluso por mês
                                             </Typography>
                                          </Box>
                                          <Typography component='div' variant='h3'>
                                             R$ 3.499,00
                                          </Typography>
                                       </Box>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Box>
                        </Grid>

                     </Grid>
                  </Box>

               </Slider >
            </Box>

            {/* Botão */}
            <Grid container justifyContent='center'>
               <Grid item>
                  <Box mt={8} mb={3} textAlign='center'>
                     <Typography component='p' variant='body1' className={classes.contrastText}>
                        Não perca tempo!
                     </Typography>
                  </Box>
                  <Button variant='contained' href='https://assinatura.locadoravigorito.com.br/'
                     classes={{
                        root: classes.button,
                        label: classes.buttonLabel,
                     }}>Comece a economizar agora mesmo
                  </Button>
               </Grid>
            </Grid>


         </Container >
      </Box >
   );
}