import React from 'react';
import { makeStyles, Button, MenuItem, Fade, Menu, Link } from '@material-ui/core';

//ICONS
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  hambMenu: {
    color: theme.palette.primary.dark,
    fontSize: '2rem',
  },
  menuItem: {
    padding: '.25rem 1.5rem',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontFamily: 'Roboto, sans-serif',
    color: theme.palette.primary.dark,
    textDecoration: 'none',
    '&:focus, &:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
      color: 'rgba(0,0,0,0.7)',
      '&:focus, &:hover': {
        color: theme.palette.primary.main,
        textDecoration: 'none',
      },
    },
  },
  icons: {
    //paddingRight: theme.spacing(0),
    color: 'white',
    fontSize: '1rem',
    paddingLeft: '.6rem',
    paddingRight: '.6rem',
    [theme.breakpoints.down('sm')]: {
      color: 'rgba(0,0,0,0.6)',
    },
  },
  button: {
    marginTop: '0.75rem',
    color: theme.palette.common.white,
    boxShadow: 'none',
    borderRadius: '8px',
    textTransform: 'none',
    padding: '0.65rem 2.5rem',
    fontSize: '1rem',
    fontWeight: '800',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));


export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const classes = useStyles();

  return (
    <div>
      <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClick}>
        <MenuIcon className={classes.hambMenu} />
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className={classes.MenuMobile}
      >
        <MenuItem>
          <Link className={classes.menuItem} href='/'>Página Inicial</Link>
        </MenuItem>
        <MenuItem>
          <Link className={classes.menuItem} href='https://assinatura.locadoravigorito.com.br/'>Nossa Frota</Link>
        </MenuItem>
        <MenuItem>
          <Link className={classes.menuItem} href='/duvidas'>Tire suas dúvidas</Link>
        </MenuItem>
        <MenuItem>
          <Link className={classes.menuItem} href='/politica-de-privacidade'>Privacidade</Link>
        </MenuItem>
        <MenuItem>
          <Link className={classes.menuItem} href='https://www.vigoritogm.com.br/'>Vigorito GM</Link>
        </MenuItem>
        <MenuItem>
          <Link className={classes.menuItem} href='https://www.vigoritovw.com.br/'>Vigorito Volks</Link>
        </MenuItem>
        <MenuItem>
          <Link className={classes.menuItem} href='https://www.vigoritonissan.com.br/'>Vigorito Nissan</Link>
        </MenuItem>
        <MenuItem>
          <Link className={classes.menuItem} href='https://www.vigoritokia.com.br/'>Vigorito KIA</Link>
        </MenuItem>
        <MenuItem>
          <Link className={classes.menuItem} href='https://www.vigoritopcd.com.br/'>Vigorito PCD</Link>
        </MenuItem>
        <MenuItem>
          <Link className={classes.menuItem} href='https://www.vigoritoconsorcio.com.br/'>Vigorito Consórcio</Link>
        </MenuItem>
        <MenuItem>
          <Button className={classes.button} variant='contained' href='https://assinatura.locadoravigorito.com.br/'>
            Quero assinar
          </Button>
        </MenuItem>
      </Menu>
    </div>
  );
}
